/**
 * Generated bundle index. Do not edit.
 */

export * from './index';

export {APPLICATION_MODULE_PROVIDERS as ɵangular_packages_core_core_s,_iterableDiffersFactory as ɵangular_packages_core_core_p,_keyValueDiffersFactory as ɵangular_packages_core_core_q,_localeFactory as ɵangular_packages_core_core_r,zoneSchedulerFactory as ɵangular_packages_core_core_t} from './src/application_module';
export {_appIdRandomProviderFactory as ɵangular_packages_core_core_g} from './src/application_tokens';
export {DefaultIterableDifferFactory as ɵangular_packages_core_core_n} from './src/change_detection/differs/default_iterable_differ';
export {DefaultKeyValueDifferFactory as ɵangular_packages_core_core_o} from './src/change_detection/differs/default_keyvalue_differ';
export {DebugElement__PRE_R3__ as ɵangular_packages_core_core_l,DebugNode__PRE_R3__ as ɵangular_packages_core_core_k,getDebugNodeR2__PRE_R3__ as ɵangular_packages_core_core_m} from './src/debug/debug_node';
export {isForwardRef as ɵangular_packages_core_core_a} from './src/di/forward_ref';
export {NullInjector as ɵangular_packages_core_core_c,injectInjectorOnly as ɵangular_packages_core_core_b} from './src/di/injector_compatibility';
export {ReflectiveInjector_ as ɵangular_packages_core_core_d} from './src/di/reflective_injector';
export {ReflectiveDependency as ɵangular_packages_core_core_e,resolveReflectiveProviders as ɵangular_packages_core_core_f} from './src/di/reflective_provider';
export {USD_CURRENCY_CODE as ɵangular_packages_core_core_u} from './src/i18n/localization';
export {getModuleFactory__PRE_R3__ as ɵangular_packages_core_core_j} from './src/linker/ng_module_factory_loader';
export {SCHEDULER as ɵangular_packages_core_core_x} from './src/render3/component_ref';
export {injectAttributeImpl as ɵangular_packages_core_core_y} from './src/render3/di';
export {TElementNode as ɵangular_packages_core_core_be} from './src/render3/interfaces/node';
export {LView as ɵangular_packages_core_core_bo} from './src/render3/interfaces/view';
export {pureFunction1Internal as ɵangular_packages_core_core_bf,pureFunction2Internal as ɵangular_packages_core_core_bg,pureFunction3Internal as ɵangular_packages_core_core_bh,pureFunction4Internal as ɵangular_packages_core_core_bi,pureFunctionVInternal as ɵangular_packages_core_core_bj} from './src/render3/pure_function';
export {getBindingRoot as ɵangular_packages_core_core_bc,getLView as ɵangular_packages_core_core_ba,getPreviousOrParentTNode as ɵangular_packages_core_core_bb,instructionState as ɵangular_packages_core_core_z,nextContextImpl as ɵangular_packages_core_core_bd} from './src/render3/state';
export {getRootContext as ɵangular_packages_core_core_bq} from './src/render3/util/view_traversal_utils';
export {createElementRef as ɵangular_packages_core_core_h,createTemplateRef as ɵangular_packages_core_core_i} from './src/render3/view_engine_compatibility';
export {getUrlSanitizer as ɵangular_packages_core_core_bk} from './src/sanitization/sanitization';
export {noSideEffects as ɵangular_packages_core_core_bp} from './src/util/closure';
export {makeParamDecorator as ɵangular_packages_core_core_bl,makePropDecorator as ɵangular_packages_core_core_bm} from './src/util/decorators';
export {getClosureSafeProperty as ɵangular_packages_core_core_bn} from './src/util/property';
export {_def as ɵangular_packages_core_core_v} from './src/view/provider';
export {DebugContext as ɵangular_packages_core_core_w} from './src/view/types';